<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>


        <v-card>
            <v-card-title class="tc-title">
                Student Data Report
            </v-card-title>
            <v-card-text>
                <v-row>

                    <v-col md="4">

                        <v-select :items="year_list" v-model="year" item-text="name" item-value="id" outlined><template
                                #label>
                                <p>Year<span style="color:red">*</span></p>
                            </template></v-select>


                    </v-col>
                    <v-col md="4">
                        <v-select :items="prg_list" autocomplete="off" v-model="program" item-text="name" item-value="id"
                            outlined><template #label>
                                <p>Program<span style="color:red">*</span></p>
                            </template></v-select>
                    </v-col>
                    <v-col sm="4">
                        <v-btn class="mt-2" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>



        </v-card>

        <br>

        <v-skeleton-loader class="ma-3 pa-2" v-if="overlay" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <div v-else>

            <div v-if="load == true && learner_list.length > 0">

                <template>
                    <v-toolbar flat class="tc-title">

                        <div justify="center" style="width: 50%; padding-left: 10%;">
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line
                                hide-details></v-text-field>
                        </div>
                        <v-spacer></v-spacer>
                        <span style="width: 40px"></span>
                        <download-excel style="padding-right: 10%;" class="mr-2 pb-3 pt-2" :data="learner_list"
                            :fields="table_fields" worksheet="My Worksheet" name="Student_data.xls">
                            <v-btn color="success" style="background-color: white;" outlined rounded><v-icon
                                    dark>mdi-file-excel</v-icon>
                                Excel</v-btn>
                        </download-excel>
                    </v-toolbar>

                </template>

                <v-app id="inspire" class>
                    <template>
                        <v-data-table id="exceltable" :headers="headers" :items="learner_list" class="elevation-1"
                            :search="search">
                        </v-data-table>
                    </template>
                </v-app>
            </div>
        </div>

        <!-- <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        valid: false,
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        iseditable: false,
        load: false,
        dialog: false,
        year_list: [],
        ay_list: [],
        org_list: null,
        prg_list: [],
        dept_list: null,
        learner_list: [],
        StudRecord: null,
        year: "",
        program: "",
        sem: "",
        ay: "",
        errorMsg: {
            organization: false,
        },
        search: "",
        file: null,
        output_load: false,
        loading3: false,
        dialog_send: false,
        isLoading: false,
        splitedStr: "",
        name: '',

        headers: [
            {
                text: "Sr. No",
                value: "sr_no",
            },
            {
                text: "Registration Number",
                value: "registration_number",
            },
            {
                text: "Name",
                value: "name",
            },
            {
                text: "Organization",
                value: "organization",
            },
            {
                text: "Personal Email",
                value: "email",
            },
            {
                text: "Institute Email",
                value: "institute_email",
            },
            {
                text: "Contact",
                value: "contact",
            },
            {
                text: "DOB",
                value: "dob",
            },

            {
                text: "Program ",
                value: "program",
            },
            {
                text: "Department ",
                value: "department",
            },
            {
                text: "Year",
                value: "year",
            },

            {
                text: "Adhar No",
                value: "adhar",
            },
            {
                text: "Passport No",
                value: "passport",
            },
            {
                text: "PAN No",
                value: "pan",
            },
            {
                text: "Is Direct Secon Year",
                value: "is_directsecondyearadmission",
            },
            {
                text: "Is Going for higher studies",
                value: "isgoingforhigherstudies",
            },
            {
                text: "Is Interested only in Internship",
                value: "is_interested_in_only_internship",
            },
        ],
        table_fields:
        {
            "Registration Number": "registration_number",
            "Name": "name",
            "Personal Email": "email",
            "Institute Email": "institute_email",
            "Contact": "contact",
            "DOB": "dob",
            "Program ": "program",
            "Department ": "department",
            "Year": "year",
            "Organization": "organization",
            "Adhar No": "adhar",
            "Passport No": "passport",
            "PAN No": "pan",
            "Is Interested only in Internship": "is_interested_in_only_internship",
            "Is Going for higher studies": "isgoingforhigherstudies",
            "Is Direct Secon Year": "is_directsecondyearadmission",
        },

    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Student" : "Edit Student";
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        overlay(val) {
            val = () => {
                this.overlay = false;
            };
        },
    },
    mounted() {
        this.onload()
    },

    methods: {
        getFullName() {
            // alert("in getFullName");
            this.editedItem.name = this.editedItem.fname + " " + this.editedItem.mname + " " + this.editedItem.lname
        },
        exportexcel() {

            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "Tpo_Lerner", //do not include extension
                fileext: ".xls" // file extension
            });
        },
        onload() {
            axios
                .post("/TPO/studentdatareport")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.overlay = false;
                        this.year_list = res.data.year_list;
                        this.prg_list = res.data.prg_list;
                        this.ay_list = res.data.aylist.sort();
                        this.prg_list.push({
                            name: 'All',
                            id: 'All'
                        })
                        this.program = "All"
                        this.year_list.push({
                            name: 'All',
                            id: 'All'
                        })
                        this.year = "All"
                        this.ay_list.push({
                            ay: 'All',
                            id: 'All'
                        })
                        this.ay = "All"

                        console.log(" fetching data!");
                    } else {
                        console.log("error fetching data!");
                    }
                });
            // .catch(error => {
            //   this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            //   window.console.log(error);
            // });
        },
        fetchReport() {
            this.overlay = true;
            const data = {
                ay: this.ay,
                year: this.year,
                program: this.program,
            };
            axios
                .post("/TPO/getStudentdataReport", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.overlay = false;
                        this.year_list = res.data.year_list;
                        this.prg_list = res.data.prg_list;
                        this.ay_list = res.data.aylist.sort();
                        this.prg_list.push({
                            name: 'All',
                            id: 'All'
                        })
                        this.program = "All"
                        this.year_list.push({
                            name: 'All',
                            id: 'All'
                        })
                        this.year = "All"
                        this.ay_list.push({
                            ay: 'All',
                            id: 'All'
                        })
                        this.ay = "All"
                        this.learner_list = res.data.learner_list;
                        if (res.data.learner_list.length == 0) {
                            this.learner_list = ''
                            this.showSnackbar(
                                "#b71c1c",
                                "No students found for selected criteria!"
                            );

                            return;
                        }
                        this.load = true
                        var index = 0;
                        var tempsize = this.learner_list[0].academic_details.length;
                        for (var j in this.learner_list) {
                            //  var tempsize = this.learner_list[j].academic_details.length;
                            if (tempsize < this.learner_list[j].academic_details.length) {
                                tempsize = this.learner_list[j].academic_details.length
                                index = j;
                            }
                        }
                        for (var i in this.learner_list[index].academic_details) {
                            var temp = {
                                text: this.learner_list[index].academic_details[i].degree,
                                value: this.learner_list[index].academic_details[i].degree,
                            }
                            this.headers.push(temp);
                            this.table_fields[this.learner_list[index].academic_details[i].degree] = this.learner_list[index].academic_details[i].degree;
                        }

                    }
                    else {
                        this.learner_list = ''
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        //console.log("error fetching data!");
                    }
                })
                .catch(error => {
                    this.learner_list = ''
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                });

        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.initializeErrorMsgFalse();
                this.editedIndex = -1;
            }, 300);
        },
        //end of the fuction

    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.downbtn {
    float: right;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.tc-title {
    background-image: linear-gradient(-90deg, skyblue, #057996);
    color: #fff;
    border-radius: 3px;
}



.edit-v-icon:hover {
    color: white;
}
</style>
